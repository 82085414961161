import React, { useState, useEffect } from 'react'
import api from './utils/api'
import isLocalHost from './utils/isLocalHost'
import RecipeReviewCard from './components/card'
import PrimarySearchAppBar from './components/appbar'
import './App.css'

function App() {
  const [stockList, setStockList] = useState([""])

  const get_todos = () => {
    api.readAll().then((todos) => {
      if (todos.message === 'unauthorized') {
        if (isLocalHost()) {
          alert('FaunaDB key is not unauthorized. Make sure you set it in terminal session where you ran `npm start`. Visit http://bit.ly/set-fauna-key for more info')
        } else {
          alert('FaunaDB key is not unauthorized. Verify the key `FAUNADB_SERVER_SECRET` set in Netlify enviroment variables is correct')
        }
        return false
      }
    
      console.log('all todos', todos.map(x => x['data']['ticker']))
      setStockList(todos.map(x => x['data']['ticker']))
  
    })
  }

  useEffect(() => {
    get_todos()
  }, []);

  return (
    <div>
      <PrimarySearchAppBar />
      {/* <button onClick={get_todos}>click to get data</button> */}
      {stockList.map((value, index)=> (
        <div style={{ display:'flex', justifyContent:'center', margin:'12px' }}>
          <RecipeReviewCard name={value} />
        </div>
      ))}


    </div>

  )
}

export default App;
